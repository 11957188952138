.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.name {
    font-size: 96px;
    margin: 0;
    text-align: center;
}

.description {
    font-size: 36px;
    text-align: center;
    margin: 0;
}

.profileImage {
    max-height: 300px;
    max-width: 300px;
    overflow: 'hidden';
    border-radius: 30px;
    margin: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.socialCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.socialCard {
    height: 75px;
    width: 75px;
    cursor: pointer;
    border-radius: 15px;
    margin: 15px;
    margin-left: 0;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 8px 24px;
    color: #ffffff;
    transition: transform .3s ease;
}
.socialCard:hover {
    transform: scale(1.1);
}