.navigationHeader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 24px;
}
.navTitle {
    font-size: 64px;
    font-weight: 800;
    margin-left: 24px;
    cursor: pointer;
}
.navigationLinks {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}
.navigationLink {
    margin: 24px;
    display: flex;
    font-size: 24px;
}
a {
    color: #ffffff;
    text-decoration: none;
}