html,
body {
    margin: 0;
    padding: 0;
    color: #ffffff;
    scroll-behavior: smooth;
}
* {
    box-sizing: border-box;
    font-family: "Helvetica Neue";
}