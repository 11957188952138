.projects_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 64px;
    min-height: 100vh;
    padding: 50px;
}
.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.projectsDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.projectCard {
    background-color: #ffffff;
    height: 300px;
    width: 300px;
    border-radius: 24px;
    margin: 30px;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 8px 24px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.title {
    font-size: 36px;
    margin: 0;
}
.details {
    /* background-color: #627D98; */
    border-radius: 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px;
    color: #000000;
}
.recentProjectsTitle, .recentProjectsInfo {
    font-size: 84px;
    color: #ffffff;
    margin: 0;
    text-align: center;
}
.recentProjectsInfo {
    font-size: 36px;
}
.imageDiv {
    overflow: hidden;
    height: inherit;
    width: inherit;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.images {
    width: inherit;
}
.projectCard .descriptionContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fafafa;
    color: #606060;
    font-size: 16px;
    bottom: 0%;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    padding: 12px 24px;
    transition: bottom .5s ease;
}
.projectCard:hover .descriptionContainer {
    position: relative;
    bottom: 100%;
}
.projectTitle {
    margin: 0;
    color:#303030;
}
.description {
    margin: 0;
}
.languagesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.languagesLogo {
    height: 36px;
    width: 36px;
    margin-right: 12px;
}
.viewProjectLink {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #138bdb;
    color: #ffffff;
    height: 50px;
    font-size: 24px;
    margin-top: 20px;
    border-radius: 15px;
    text-decoration: none;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 50em;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    color: #000000;
    outline: none;
}
.modalHeader {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
}