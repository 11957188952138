.footerContainer {
    height: 50px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.footerTitle, .footerDetails {
    margin: 0;
    outline: none;
    text-decoration: none;
    color: #191919;
    font-size: 18px;
}
.footerTitle {
    font-size: 24px;
}