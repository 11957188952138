.aboutMeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 50px;
    min-height: 100vh;
    background-color: #101f37;
}
.aboutMeDescriptionContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 50px;
}
.aboutMeHeading {
    font-size: 64px;
    margin-top: 0;
}
.aboutMeImage {
    height: 500px;
    border-radius: 15px;
    background-color: #ffffff;
}
.aboutMeContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.description {
    font-size: 36px;
    text-align: center;
    max-width: 450px;
    margin: 0;
}